import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { NavModelItem } from '@grafana/data';
import { Page } from 'app/core/components/Page/Page';
import { GrafanaRouteComponentProps } from 'app/core/navigation/types';
import { Loader } from 'app/features/plugins/admin/components/Loader';

import { samlSteps } from '..';
import { EnterpriseStoreState, SAMLStepKey } from '../../types';

import { loadSAMLSettings } from './state/actions';

interface OwnProps extends GrafanaRouteComponentProps<{ step: SAMLStepKey }> {}

function mapStateToProps(state: EnterpriseStoreState, props: OwnProps) {
  const { isLoading, samlSettings } = state.samlConfig;
  const { step: activeStep } = props.match.params;
  return {
    isLoading,
    samlSettings,
    activeStep,
  };
}

const mapDispatchToProps = {
  loadSAMLSettings,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = OwnProps & ConnectedProps<typeof connector>;

const pageNav: NavModelItem = {
  text: 'Setup SAML single sign on',
  subTitle: `This configurator will guide you throug the process of configuration of SAML. You will need to follow
    steps and visit Identity Provider's applicatation to connect it with Grafana (Service Provider). You can
    track you progress.`,
  icon: 'shield',
  id: 'SAML',
  breadcrumbs: [{ title: 'Authentication', url: 'admin/authentication' }],
};

export const SetupSAMLPageUnconnected = ({
  isLoading,
  samlSettings,
  activeStep,
  loadSAMLSettings,
}: Props): JSX.Element => {
  useEffect(() => {
    loadSAMLSettings();
  }, [loadSAMLSettings]);

  const renderStep = useCallback(() => {
    const Component = samlSteps.find(({ id }) => id === activeStep)?.component;
    if (!Component) {
      return null;
    }
    return <Component />;
  }, [activeStep]);

  return (
    <Page navId="authentication" pageNav={pageNav}>
      <Page.Contents>{isLoading || isEmpty(samlSettings) ? <Loader /> : renderStep()}</Page.Contents>
    </Page>
  );
};

export const SetupSAMLPage = connector(SetupSAMLPageUnconnected);
