// Code generated - EDITING IS FUTILE. DO NOT EDIT.
//
// Generated by:
//     public/app/plugins/gen.go
// Using jennies:
//     TSTypesJenny
//     PluginTSTypesJenny
//
// Run 'make gen-cue' from repository root to regenerate.

import * as common from '@grafana/schema';

export const DataQueryModelVersion = Object.freeze([0, 0]);

export interface MetricStat {
  accountId?: string;
  dimensions?: Dimensions;
  matchExact?: boolean;
  metricName?: string;
  namespace: string;
  period?: string;
  region: string;
  statistic?: string;
  /**
   * @deprecated use statistic
   */
  statistics?: Array<string>;
}

export const defaultMetricStat: Partial<MetricStat> = {
  statistics: [],
};

export type Dimensions = Record<string, (string | Array<string>)>;

export interface CloudWatchMetricsQuery extends common.DataQuery, MetricStat {
  alias?: string;
  /**
   * Math expression query
   */
  expression?: string;
  /**
   * common props
   */
  id: string;
  label?: string;
  metricEditorMode?: MetricEditorMode;
  metricQueryType?: MetricQueryType;
  queryMode?: CloudWatchQueryMode;
  sql?: SQLExpression;
  sqlExpression?: string;
}

export type CloudWatchQueryMode = ('Metrics' | 'Logs' | 'Annotations');

export enum MetricQueryType {
  Query = 1,
  Search = 0,
}

export enum MetricEditorMode {
  Builder = 0,
  Code = 1,
}

export interface SQLExpression {
  from?: (QueryEditorPropertyExpression | QueryEditorFunctionExpression);
  groupBy?: QueryEditorArrayExpression;
  limit?: number;
  orderBy?: QueryEditorFunctionExpression;
  orderByDirection?: string;
  select?: QueryEditorFunctionExpression;
  where?: QueryEditorArrayExpression;
}

export interface QueryEditorFunctionExpression {
  name?: string;
  parameters?: Array<QueryEditorFunctionParameterExpression>;
  type: QueryEditorExpressionType.Function;
}

export const defaultQueryEditorFunctionExpression: Partial<QueryEditorFunctionExpression> = {
  parameters: [],
};

export enum QueryEditorExpressionType {
  And = 'and',
  Function = 'function',
  FunctionParameter = 'functionParameter',
  GroupBy = 'groupBy',
  Operator = 'operator',
  Or = 'or',
  Property = 'property',
}

export interface QueryEditorFunctionParameterExpression {
  name?: string;
  type: QueryEditorExpressionType.FunctionParameter;
}

export interface QueryEditorPropertyExpression {
  property: QueryEditorProperty;
  type: QueryEditorExpressionType.Property;
}

export interface QueryEditorGroupByExpression {
  property: QueryEditorProperty;
  type: QueryEditorExpressionType.GroupBy;
}

export interface QueryEditorOperatorExpression {
  /**
   * TS type is operator: QueryEditorOperator<QueryEditorOperatorValueType>, extended in veneer
   */
  operator: QueryEditorOperator;
  property: QueryEditorProperty;
  type: QueryEditorExpressionType.Operator;
}

/**
 * TS type is QueryEditorOperator<T extends QueryEditorOperatorValueType>, extended in veneer
 */
export interface QueryEditorOperator {
  name?: string;
  value?: (QueryEditorOperatorType | Array<QueryEditorOperatorType>);
}

export type QueryEditorOperatorValueType = (QueryEditorOperatorType | Array<QueryEditorOperatorType>);

export type QueryEditorOperatorType = (string | boolean | number);

export interface QueryEditorProperty {
  name?: string;
  type: QueryEditorPropertyType;
}

export enum QueryEditorPropertyType {
  String = 'string',
}

export interface QueryEditorArrayExpression {
  /**
   * TS type expressions: QueryEditorExpression[] | QueryEditorArrayExpression[], extended in veneer
   */
  expressions: unknown;
  type: (QueryEditorExpressionType.And | QueryEditorExpressionType.Or);
}

/**
 * QueryEditorArrayExpression is added in veneer
 */
export type QueryEditorExpression = (QueryEditorPropertyExpression | QueryEditorGroupByExpression | QueryEditorFunctionExpression | QueryEditorFunctionParameterExpression | QueryEditorOperatorExpression);

export interface CloudWatchLogsQuery extends common.DataQuery {
  expression?: string;
  id: string;
  /**
   * deprecated, use logGroups instead
   */
  logGroupNames?: Array<string>;
  logGroups?: Array<LogGroup>;
  queryMode: CloudWatchQueryMode;
  region: string;
  statsGroups?: Array<string>;
}

export const defaultCloudWatchLogsQuery: Partial<CloudWatchLogsQuery> = {
  logGroupNames: [],
  logGroups: [],
  statsGroups: [],
};

export interface LogGroup {
  accountId?: string;
  accountLabel?: string;
  arn: string;
  name: string;
}

export interface CloudWatchAnnotationQuery extends common.DataQuery, MetricStat {
  actionPrefix?: string;
  alarmNamePrefix?: string;
  prefixMatching?: boolean;
  queryMode: CloudWatchQueryMode;
}

export interface CloudWatch {}
